import { useAtom } from 'jotai'
import React, { ReactElement, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import VideoSvg from '../assets/Video.svg'
import {
  agoraTokenAtom,
  channelAtom,
  getAppointmentIdFromURL,
  isRejoinAtom,
  uidAtom,
} from '../atoms'
import { Button, CancelButton } from '../components/Button'

const Wrap = styled.div`
  width: 90%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
`

const backendApiUrl = import.meta.env.BACKEND_SERVICE
const urlAppointment = getAppointmentIdFromURL()

const Start = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const [channel, setChannel] = useAtom(channelAtom)
  const [isRejoin, setIsRejoin] = useAtom(isRejoinAtom)
  const [, setAgoraToken] = useAtom(agoraTokenAtom)
  const [uid] = useAtom(uidAtom)

  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    if (channel && channel !== '') {
      setLoading(true)

      try {
        const response = await fetch(`${backendApiUrl}/twilio-config/token`, {
          body: JSON.stringify({
            appointmentCode: channel,
            uid,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })

        const { token } = await response.json()
        setAgoraToken(token)

        console.log('RUBEEEEN', location.search)

        navigate({
          pathname: '/call',
          search: location.search,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }, [channel, location.search, navigate, setAgoraToken, uid])

  const handleCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChannel(event.target.value)
    },
    [setChannel],
  )

  console.log(isRejoin)

  return (
    <Wrap>
      <StyledForm>
        {loading ? (
          <h3>Authenticating...</h3>
        ) : !isRejoin ? (
          <>
            <h3 className="text-2xl font-bold">Enter room code</h3>
            <input
              type="text"
              onChange={handleCodeChange}
              name="appointmentCode"
              defaultValue={urlAppointment || ``}
              className="appearance-none border rounded-full w-full py-2 px-4 text-white leading-tight focus:outline-none"
            />
            <Button onClick={handleSubmit}>
              <img src={VideoSvg} width={20} /> Join video call
            </Button>
          </>
        ) : (
          <>
            <h3 className="text-2xl font-bold">Rejoin Call?</h3>

            <Buttons>
              <Button onClick={handleSubmit}>
                <img src={VideoSvg} width={20} /> Yes
              </Button>

              <CancelButton
                onClick={() => {
                  setIsRejoin(false)
                }}
              >
                <img src={VideoSvg} width={20} /> No
              </CancelButton>
            </Buttons>
          </>
        )}
      </StyledForm>
    </Wrap>
  )
}

export { Start }
