import styled from 'styled-components'

const ConfirmButton = styled.button`
  background: #09b968;
  text-transform: uppercase;
  color: white;
  padding: 0px 24px;
  height: 44px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  transition: background 0.3s ease;
  width: 100%;
  outline: none;
  display: flex;
  flex-flow: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: #07a256;
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
`

const CancelButton = styled.button`
  background: #bf002f;
  text-transform: uppercase;
  color: white;
  padding: 0px 24px;
  height: 44px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  transition: background 0.3s ease;
  width: 100%;
  outline: none;
  display: flex;
  flex-flow: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: #d20034;
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
`

export { ConfirmButton as Button, CancelButton }
