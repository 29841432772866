import './App.css'
import 'webrtc-adapter'

import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import Calendar from './assets/Calendar.svg'
import Clock from './assets/Clock.svg'
import XpertLogo from './assets/XpertClinicsLogo.svg'
import { agoraTokenAtom, meetingAtom } from './atoms'
import { useViewportHeight } from './utils'

const Wrap = styled.div`
  background-color: #2c2c2c;
  color: #ececec;
  position: fixed;
  width: 100%;
  top: 0;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
`

const Header = styled.div`
  background: #222222;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 0 11px;
  display: flex;
  align-items: center;
  z-index: 5;
`

const Meeting = styled.div`
  display: flex;
  position: fixed;
  top: 70px;
  left: 0p;
  z-index: 5;
  padding: 0 0 0 20px;
  flex-flow: column;
  gap: 2px;
  z-index: 200;
`

const Name = styled.h2`
  font-size: 20px;
  font-weight: 600;
`

const Detail = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
`

const Svg = styled.div`
  width: 40px;
`

const Time = styled.img`
  transform: translateX(-2px);
`

function App() {
  const [meeting] = useAtom(meetingAtom)
  const [, setAgoraToken] = useAtom(agoraTokenAtom)
  const height = useViewportHeight()

  useEffect(() => {
    return () => {
      setAgoraToken(null)
    }
  }, [setAgoraToken])

  return (
    <Wrap style={{ height }}>
      <Header>
        <img src={XpertLogo} />
      </Header>
      <Meeting>
        {meeting.name && <Name>{meeting.name}</Name>}
        {meeting.date && (
          <Detail>
            <Svg>
              <img alt="calendar" src={Calendar} width={20} />
            </Svg>
            {meeting.date}
          </Detail>
        )}
        {meeting.time && (
          <Detail>
            <Svg>
              <Time alt="clock" src={Clock} width={25} />
            </Svg>
            {meeting.time}
          </Detail>
        )}
      </Meeting>
      <Outlet />
    </Wrap>
  )
}

export default App
