import { AgoraRTCProvider } from 'agora-rtc-react'
import { useAtom } from 'jotai'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App'
import { agoraClientAtom } from './atoms'
import { Call } from './screens/Call'
import { loader as startLoader } from './screens/loader'
import { Start } from './screens/Start'

const router = createBrowserRouter([
  {
    children: [
      {
        element: <Start />,
        index: true,
        loader: startLoader,
      },
      {
        element: <Call />,
        path: 'call',
      },
    ],
    element: <App />,
    path: '/',
  },
])

const RouterRoot = () => {
  const [agoraClient] = useAtom(agoraClientAtom)

  return (
    <AgoraRTCProvider client={agoraClient}>
      <RouterProvider router={router} />
    </AgoraRTCProvider>
  )
}

export default RouterRoot
